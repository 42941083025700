import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { supabase } from "../supabaseClient"; // 确保路径正确

const RedeemDialog = () => {
  const [code, setCode] = useState(""); // 存储用户输入的兑换码
  const [error, setError] = useState(""); // 存储错误信息
  const [photoUrl, setPhotoUrl] = useState(""); // 存储照片 URL
  const [success, setSuccess] = useState(false); // 标记是否验证成功

  const handleSubmit = async () => {
    const { data, error } = await supabase
      .from("photos")
      .select("photo_url")
      .eq("redeem_code", code);

    if (error) {
      console.error("Error querying Supabase:", error);
      setError("服务器错误，请稍后再试。");
      return;
    }

    if (data.length > 0) {
      setSuccess(true);
      setPhotoUrl(data[0].photo_url);
      setError("");
    } else {
      setError("无效的兑换码。");
      setSuccess(false);
    }
  };

  return (
    <>
      <Backdrop
        open={true} // 固定为 true，因为不需要关闭对话框
        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }} // 背景颜色为白色并带有透明度
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={true} // 固定为 true，因为不需要关闭对话框
        onClose={() => {}} // 不需要关闭对话框的处理函数
        PaperProps={{
          sx: {
            backgroundColor: "#fff", // 对话框背景色设置为白色
            borderRadius: 2, // 可选：设置圆角
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // 可选：设置阴影
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#f48fb1", // 对话框标题背景颜色
            color: "#fff", // 对话框标题文字颜色
            "& .MuiTypography-root": {
              color: "#fff", // 确保标题文字颜色为白色
            },
          }}
        >
          蕾妮の专属盲盒
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="redeem-code"
            label="专属兑换码"
            type="text"
            fullWidth
            variant="outlined"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            error={!!error}
            helperText={error}
            sx={{
              "& .MuiInputLabel-root": { color: "#f48fb1" }, // 标签颜色
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#f48fb1" }, // 边框颜色
                "&:hover fieldset": { borderColor: "#f06292" }, // 悬停边框颜色
                "&.Mui-focused fieldset": { borderColor: "#f48fb1" }, // 聚焦边框颜色
                "& .MuiOutlinedInput-input": { color: "#000" }, // 输入文本颜色
              },
              "& .MuiInputLabel-shrink": { color: "#f48fb1" }, // 上浮标签颜色
            }}
          />
          {success && photoUrl && (
            <Box sx={{ marginTop: 2, textAlign: "center" }}>
              <img
                src={photoUrl}
                alt="Redeemed"
                style={{ maxWidth: "100%", borderRadius: 8 }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#fce4ec" }}>
          <Button
            onClick={handleSubmit}
            sx={{
              backgroundColor: "#f48fb1",
              color: "#fff",
              "&:hover": { backgroundColor: "#f06292" },
            }}
          >
            兑换
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RedeemDialog;
