import React from "react";
import RedeemDialog from "./Fuct/RedeemDialog"; // 确保路径正确
import { CssBaseline, Container, Box } from "@mui/material";

const App = () => {
  return (
    <div style={{ minHeight: "100vh", position: "relative" }}>
      <CssBaseline /> {/* 确保应用的全局样式被重置 */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#fff", // 全白背景
          zIndex: -1, // 确保背景层在对话框下面
        }}
      />
      <Container>
        <RedeemDialog />
      </Container>
    </div>
  );
};

export default App;
